import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetReconciliationContextQuery } from '../generated/graphql'
import { ReconciliationStep } from '../models/reconciliation'
import { ReconciliationRecordType } from '../pages/Reconciliation/Layout/RecordSelector'
import { getStepFromPath, hasWorkflowBeenCompleted, mapToStep, nextStep } from '../utils/stepMap'
import useReconciliationContext from './useReconciliationContext'

interface UseReconciliationForcedNavigation {
  isContextLoading: boolean
  contextHasErrors: boolean
  contextError: unknown
  refetchContext: () => void
}

export default function useReconciliationForcedNavigation(accountId: number): UseReconciliationForcedNavigation {
  const location = useLocation()
  const step = getStepFromPath(location.pathname)
  const [isContextLoading, setIsContextLoading] = React.useState(true)

  const navigate = useNavigate()
  const { storeContext, setRecordSlotA, setRecordSlotB, setDisableRecordSelector, setMainWorkflowCompleted } =
    useReconciliationContext()

  const {
    error: contextError,
    refetch: refetchContext,
    isError: contextHasErrors,
  } = useGetReconciliationContextQuery(
    { accountId },
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: true,
      cacheTime: 0,
      onError: () => {
        setIsContextLoading(false)
      },
      onSuccess: ({ reconciliationContext }) => {
        storeContext?.(reconciliationContext)
        setDisableRecordSelector?.(false)
        setRecordSlotA?.(ReconciliationRecordType.TaxPayerVersion)
        setRecordSlotB?.(ReconciliationRecordType.AuditorVersion)

        if (reconciliationContext.completedSteps) {
          const completedSteps = reconciliationContext.completedSteps.map(mapToStep)
          const mainWorkflowCompleted = hasWorkflowBeenCompleted(completedSteps)
          const currentStep = step ?? ReconciliationStep.AccountInformation
          setMainWorkflowCompleted?.(mainWorkflowCompleted)

          if (hasPermissions(currentStep, completedSteps)) {
            setIsContextLoading(false)
            return
          }

          navigate(nextStep(completedSteps, accountId))
        }
      },
    },
  )

  React.useEffect(() => {
    if (window.location.href) {
      refetchContext()
      setIsContextLoading(true)
    }

    // eslint-disable-next-line
  }, [window.location.href])

  return { isContextLoading, contextHasErrors, contextError, refetchContext }
}

function hasPermissions(step: ReconciliationStep, completedSteps: ReconciliationStep[]): boolean {
  const permissionsByStep = {
    [ReconciliationStep.Contacts]: [ReconciliationStep.AccountInformation],
    [ReconciliationStep.PropertyAssetList]: [ReconciliationStep.AccountInformation, ReconciliationStep.Contacts],
    [ReconciliationStep.LeasedAssetList]: [
      ReconciliationStep.AccountInformation,
      ReconciliationStep.Contacts,
      ReconciliationStep.PropertyAssetList,
    ],
    [ReconciliationStep.Summary]: [
      ReconciliationStep.AccountInformation,
      ReconciliationStep.Contacts,
      ReconciliationStep.PropertyAssetList,
      ReconciliationStep.LeasedAssetList,
    ],
    [ReconciliationStep.Reconciled]: [
      ReconciliationStep.AccountInformation,
      ReconciliationStep.Contacts,
      ReconciliationStep.PropertyAssetList,
      ReconciliationStep.LeasedAssetList,
      ReconciliationStep.Summary,
    ],
  }

  if (step === ReconciliationStep.AccountInformation) {
    return true
  }

  const permissions = permissionsByStep[step]
  return permissions.every((permission) => completedSteps.includes(permission))
}
