import * as Sentry from '@sentry/react'

import { SENTRY_DSN, SENTRY_ENVIRONMENT } from '../config'

if (['development', 'test', 'conversion', 'release', 'production'].includes(SENTRY_ENVIRONMENT)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost'],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
