import React from 'react'

interface ProviderProps {
  children: React.ReactNode
}

interface AccountLayoutStateContext {
  disableMenuItems: boolean
  setDisableMenuItems: React.Dispatch<React.SetStateAction<boolean>>
}

const BlockedStateContext = React.createContext<AccountLayoutStateContext>({} as AccountLayoutStateContext)

export function AccountLayoutStateProvider({ children }: ProviderProps): JSX.Element {
  const [disableMenuItems, setDisableMenuItems] = React.useState(false)
  const context = {
    disableMenuItems,
    setDisableMenuItems,
  }

  return <BlockedStateContext.Provider value={context}>{children}</BlockedStateContext.Provider>
}

export default function useAccountLayoutState(): AccountLayoutStateContext {
  return React.useContext(BlockedStateContext)
}
