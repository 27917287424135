import { UseQueryResult } from '@tanstack/react-query'
import React from 'react'

import { GetCurrentTaxYearQuery, useGetCurrentTaxYearQuery } from '../generated/graphql'
import useAuth from './useAuth'

interface Props {
  setCurrentTaxYear: React.Dispatch<number>
}

const FIFTEEN_MINUTES = 1_000 * 60 * 15

export default function useGetCurrentTaxYear(props: Props): UseQueryResult<GetCurrentTaxYearQuery> {
  const { authInformation } = useAuth()

  return useGetCurrentTaxYearQuery(
    {},
    {
      cacheTime: FIFTEEN_MINUTES,
      onSuccess(data: GetCurrentTaxYearQuery) {
        if (!data) {
          return
        }

        props.setCurrentTaxYear(data.currentTaxYear)
      },
      enabled: Boolean(authInformation && !authInformation.isMasterUser),
    },
  )
}
