import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from './Footer'
import Header from './Header'

interface Props {
  isPageNotFound?: boolean
}

export default function Layout(props: Props): JSX.Element {

  return (
    <React.Fragment>
      <Box display="flex">
        <Header hideNavigationDrawer={props.isPageNotFound} />

        <Stack direction="column" component="main" sx={{ flexGrow: 1, p: 3 }}>
          {!props.isPageNotFound && <Toolbar />}
          <Outlet />
          <Footer />
        </Stack>

      </Box>
    </React.Fragment>
  )
}
