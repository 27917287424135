import React, { useState } from 'react'
import { SessionContext } from '../generated/graphql'
import { ReconciliationStep } from '../models/reconciliation'
import { ReconciliationRecordType } from '../pages/Reconciliation/Layout/RecordSelector'
import { mapToStep } from '../utils/stepMap'
import useLocalStorage from './useLocalStorage'

interface ReconciliationProviderProps {
  children: React.ReactNode
}

export interface Context {
  taxYear: number
  accountId: number
  accountNumber: string
  businessName: string
  completedSteps: ReconciliationStep[]
  fullName: string
  buildingPermitExists: boolean
}

interface ReconciliationContext {
  reconciliationContext: Context | null
  mainWorkflowCompleted: boolean
  recordSlotA: string
  recordSlotB: string
  disableRecordSelector?: boolean
  setRecordSlotA?: (record: string) => void
  setRecordSlotB?: (record: string) => void
  setDisableRecordSelector?: (disable: boolean) => void
  setMainWorkflowCompleted?: (completed: boolean) => void
  storeContext?: (sessionContext: SessionContext) => void
  removeContext?: () => void
}

export const ReconciliationContext = React.createContext<ReconciliationContext>({} as ReconciliationContext)

export function ReconciliationProvider({ children }: ReconciliationProviderProps): JSX.Element {
  const [reconciliationContext, setReconciliationContext] = useLocalStorage<Context | null>(
    'reconciliationContext',
    null,
  )

  const [disableRecordSelector, setDisableRecordSelector] = useState(false)
  const [recordSlotA, setRecordSlotA] = useState<string>(ReconciliationRecordType.TaxPayerVersion)
  const [recordSlotB, setRecordSlotB] = useState<string>(ReconciliationRecordType.AuditorVersion)
  const [mainWorkflowCompleted, setMainWorkflowCompleted] = useState(false)

  const storeContext = async (sessionContext: SessionContext): Promise<void> => {
    const completedSteps = sessionContext.completedSteps.map(mapToStep)
    const context = {
      taxYear: sessionContext.taxYear,
      accountId: sessionContext.accountId,
      accountNumber: sessionContext.accountNumber,
      businessName: sessionContext.businessName,
      fullName: sessionContext.fullName,
      buildingPermitExists: sessionContext.buildingPermitExists ?? false,
      completedSteps,
    }
    setReconciliationContext(context)
  }

  const removeContext = (): void => {
    setReconciliationContext(null)
  }

  const context = {
    reconciliationContext,
    mainWorkflowCompleted,
    recordSlotA,
    recordSlotB,
    disableRecordSelector,
    setRecordSlotA,
    setRecordSlotB,
    setDisableRecordSelector,
    setMainWorkflowCompleted,
    storeContext,
    removeContext,
  }

  return <ReconciliationContext.Provider value={context}>{children}</ReconciliationContext.Provider>
}

export default function useReconciliationContext(): ReconciliationContext {
  return React.useContext(ReconciliationContext)
}
