export enum ReconciliationStep {
  AccountInformation,
  Contacts,
  PropertyAssetList,
  LeasedAssetList,
  Summary,
  Reconciled,
}

export enum ReconciliationStatus {
  unavailable = 'unavailable',
  inProgress = 'inProgress',
  available = 'available',
  done = 'done',
}

export enum AccountStatus {
  NotFiled = 'Not Filed',
  Filed = 'Filed',
  Rolled = 'Rolled',
  InProcess = 'InProcess',
  Suspended = 'Suspended',
  Submitted = 'Submitted',
  InReconcile = 'InReconcile',
  Locked = 'Locked',
  Reconciled = 'Reconciled',
}

export function mapStatusToReconcilationStatus(statusChar: string): ReconciliationStatus {
  switch (statusChar) {
    case AccountStatus.Rolled:
    case AccountStatus.InProcess:
    case AccountStatus.Suspended:
      return ReconciliationStatus.unavailable
    case AccountStatus.Submitted:
    case AccountStatus.Filed:
      return ReconciliationStatus.available
    case AccountStatus.InReconcile:
      return ReconciliationStatus.inProgress
    case AccountStatus.Locked:
    case AccountStatus.Reconciled:
      return ReconciliationStatus.done
    default:
      return ReconciliationStatus.unavailable
  }
}
