import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import BusinessIcon from '@mui/icons-material/Business'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import VerifiedIcon from '@mui/icons-material/Verified'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useReconciliationContext from '../../../hooks/useReconciliationContext'

export enum ReconciliationRecordType {
  OriginalVersion = 'originalVersion',
  TaxPayerVersion = 'taxPayerVersion',
  AuditorVersion = 'auditorVersion',
}

export const recordTypeWeight: { [key in ReconciliationRecordType]: number } = {
  [ReconciliationRecordType.OriginalVersion]: 1,
  [ReconciliationRecordType.TaxPayerVersion]: 2,
  [ReconciliationRecordType.AuditorVersion]: 3,
}

const recordOptions = [
  { id: ReconciliationRecordType.OriginalVersion, label: 'Original Record' },
  { id: ReconciliationRecordType.TaxPayerVersion, label: 'Tax Payer Record' },
  { id: ReconciliationRecordType.AuditorVersion, label: 'Auditor Record' },
]

export type TranslationKeys = 'auditorVersion' | 'taxPayerVersion' | 'originalVersion'
export const versionIcons: Record<string, JSX.Element> = {
  auditorVersion: <AccountBalanceIcon fontSize="small" />,
  taxPayerVersion: <BusinessIcon fontSize="small" />,
  originalVersion: <VerifiedIcon fontSize="small" />,
}

export default function RecordSelector(): JSX.Element {
  const { t } = useTranslation()
  const { disableRecordSelector, recordSlotA, setRecordSlotA, recordSlotB, setRecordSlotB } =
    useReconciliationContext()

  const onRecordSlotAChange = (event: SelectChangeEvent): void => {
    setRecordSlotA?.(event.target.value as string)
  }

  const onRecordSlotBChange = (event: SelectChangeEvent): void => {
    setRecordSlotB?.(event.target.value as string)
  }

  const onSwitchRecords = (): void => {
    setRecordSlotA?.(recordSlotB)
    setRecordSlotB?.(recordSlotA)
  }

  return (
    <Stack component={Paper} elevation={2} sx={{ bgcolor: 'primary.main', padding: 1.25 }}>
      <Typography variant="h5" color="white" fontWeight="bold">
        {t('compareRecords')}
      </Typography>
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        spacing={2}
        alignItems="center"
        sx={{ bgcolor: 'background.paper', borderRadius: 1, padding: 1.75, marginTop: 0.5 }}
      >
        <FormControl variant="standard" disabled={disableRecordSelector} fullWidth>
          <InputLabel id="record-slot-a-label">{t('compareRecordItem', { item: 'A' })}</InputLabel>
          <Select
            labelId="record-slot-a-label"
            id="record-slot-a"
            value={recordSlotA}
            label={t('compareRecordItem', { item: 'A' })}
            onChange={onRecordSlotAChange}
          >
            {recordOptions.map((option) => (
              <MenuItem key={option.id} value={option.id} disabled={recordSlotB === option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton sx={{ width: 40, height: 40 }} onClick={onSwitchRecords} disabled={disableRecordSelector}>
          <SyncAltIcon />
        </IconButton>

        <FormControl variant="standard" disabled={disableRecordSelector} fullWidth>
          <InputLabel id="record-slot-b-label">{t('compareRecordItem', { item: 'B' })}</InputLabel>
          <Select
            labelId="record-slot-b-label"
            id="record-slot-b"
            value={recordSlotB}
            label={t('compareRecordItem', { item: 'B' })}
            onChange={onRecordSlotBChange}
          >
            {recordOptions.map((option) => (
              <MenuItem key={option.id} value={option.id} disabled={recordSlotA === option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  )
}
