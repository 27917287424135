// SuspenseRoute.tsx
import React from 'react'
import Loader from '../Loader'

const SuspenseRoute = ({ Component }: { Component: React.ComponentType }): JSX.Element => (
  <React.Suspense fallback={<Loader loading />}>
    <Component />
  </React.Suspense>
)

export default SuspenseRoute
