import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useReconciliationContext from '../../hooks/useReconciliationContext'
import { nextStep } from '../../utils/stepMap'
import Loader from '../Loader'

export default function StepRedirector(): JSX.Element {
  const { reconciliationContext } = useReconciliationContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (reconciliationContext) {
      navigate(nextStep(reconciliationContext.completedSteps, reconciliationContext.accountId))
    }
  }, [reconciliationContext, navigate])

  return <Loader loading />
}
