import { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import type { GetCurrentTaxYearQuery } from '../generated/graphql'

import useGetCurrentTaxYear from './useGetCurrentTaxYear'

interface GlobalContextProviderProps {
  children: React.ReactNode
}

interface GlobalContext {
  currentTaxYear: number
  getCurrentTaxYearQueryResponse: UseQueryResult<GetCurrentTaxYearQuery>
}

const GlobalContext = React.createContext<GlobalContext>({} as GlobalContext)

export function GlobalContextProvider({ children }: GlobalContextProviderProps): JSX.Element {
  const [currentTaxYear, setCurrentTaxYear] = React.useState<number>(new Date().getFullYear())

  const getCurrentTaxYearQueryResponse = useGetCurrentTaxYear({ setCurrentTaxYear })

  const authContext = {
    currentTaxYear,
    getCurrentTaxYearQueryResponse,
  }

  return <GlobalContext.Provider value={authContext}>{children}</GlobalContext.Provider>
}

export default function useGlobalContext(): GlobalContext {
  return React.useContext(GlobalContext)
}
