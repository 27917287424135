import React from 'react'
import Snackbar, { SnackbarProps } from './Snackbar'

interface SnackbarContext {
  openSnackbar: (props: SnackbarProps) => void
  closeSnackbar: () => void
}

const SnackbarContext = React.createContext<SnackbarContext>({
  openSnackbar(): void { return },
  closeSnackbar(): void { return },
})

interface SnackbarProviderProps {
  children: React.ReactNode
}


export function SnackbarProvider({ children }: SnackbarProviderProps): JSX.Element {
  const [snackbarProps, setSnackbarProps] = React.useState<SnackbarProps | null>(null)

  const openSnackbar = (newProps: SnackbarProps): void => {
    setSnackbarProps(newProps)
  }

  const closeSnackbar = (): void => {
    setSnackbarProps(null)
  }

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      {snackbarProps && <Snackbar {...snackbarProps} onClose={closeSnackbar} />}
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = (): SnackbarContext  => {
  return React.useContext<SnackbarContext>(SnackbarContext)
}
