import { default as i18n, InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import numbro from 'numbro'
import { initReactI18next } from 'react-i18next'

export const initOptions: InitOptions = {
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: ['en'],
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    format: (value: Iterable<string>, format?: string, language?: string): string => {
      if (format === 'and') return conjunctionFormat(value, language)
      return value as string
    },
  },
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json', // Path where your translation files are located
  },
}

// TODO: Replace the Copyright and the Login Intro with their actual text
// https://mcatcama.visualstudio.com/Puma/_workitems/edit/5407
i18n.use(initReactI18next).use(HttpApi).use(LanguageDetector).init(initOptions)

i18n.on('languageChanged', (language) => {
  numbro.setLanguage(language)
})

function conjunctionFormat(value: Iterable<string>, language?: string): string {
  return new Intl.ListFormat(language as Intl.Locale | undefined, { style: 'long', type: 'conjunction' }).format(
    value as Iterable<string>,
  )
}
