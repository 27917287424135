import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useGlobalContext from '../../hooks/useGlobalContext'
import paths from '../../utils/paths'

export default function ProtectedRoute(): JSX.Element {
  const { user, authInformation } = useAuth()
  const { getCurrentTaxYearQueryResponse } = useGlobalContext()
  const location = useLocation()

  React.useEffect(() => {
    if (location && authInformation) {
      getCurrentTaxYearQueryResponse.refetch()
    }
  }, [getCurrentTaxYearQueryResponse, location, authInformation])

  if (!user) {
    return <Navigate to={paths.login} replace />
  }

  return <Outlet />
}
