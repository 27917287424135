import { County } from '../models/api'

export const noCounty: County = {
  id: 0,
  name: undefined,
}

export const counties: { [id: string]: County } = {
  beaver: {
    id: 1,
    name: 'Beaver',
  },
  boxelder: {
    id: 2,
    name: 'Box Elder',
  },
  cache: {
    id: 3,
    name: 'Cache',
  },
  carbon: {
    id: 4,
    name: 'Carbon',
  },
  daggett: {
    id: 5,
    name: 'Daggett',
  },
  davis: {
    id: 6,
    name: 'Davis',
  },
  duchesne: {
    id: 7,
    name: 'Duchesne',
  },
  emery: {
    id: 8,
    name: 'Emery',
  },
  garfield: {
    id: 9,
    name: 'Garfield',
  },
  grand: {
    id: 10,
    name: 'Grand',
  },
  iron: {
    id: 11,
    name: 'Iron',
  },
  juab: {
    id: 12,
    name: 'Juab',
  },
  kane: {
    id: 13,
    name: 'Kane',
  },
  millard: {
    id: 14,
    name: 'Millard',
  },
  morgan: {
    id: 15,
    name: 'Morgan',
  },
  piute: {
    id: 16,
    name: 'Piute',
  },
  rich: {
    id: 17,
    name: 'Rich',
  },
  saltlake: {
    id: 18,
    name: 'Salt Lake',
  },
  sanjuan: {
    id: 19,
    name: 'San Juan',
  },
  sanpete: {
    id: 20,
    name: 'Sanpete',
  },
  sevier: {
    id: 21,
    name: 'Sevier',
  },
  summit: {
    id: 22,
    name: 'Summit',
  },
  tooele: {
    id: 23,
    name: 'Tooele',
  },
  unintah: {
    id: 24,
    name: 'Unintah',
  },
  utah: {
    id: 25,
    name: 'Utah',
  },
  wasatch: {
    id: 26,
    name: 'Wasatch',
  },
  washington: {
    id: 27,
    name: 'Washington',
  },
  wayne: {
    id: 28,
    name: 'Wayne',
  },
  weber: {
    id: 29,
    name: 'Weber',
  },
}

const getCountySubdomain = (hostname: string): string => {
  const hostnameLowerCase = hostname.toLowerCase()

  const domainParts = hostnameLowerCase.split('.')

  let countySubdomain = domainParts[0]

  if (countySubdomain === 'www') {
    countySubdomain = domainParts[1]
  }

  return countySubdomain.split('-')[0]
}

export const getCountyFromHostname = (hostname: string): County => {
  const countySubdomain = getCountySubdomain(hostname)

  const county = counties[countySubdomain]
  if (!county) {
    return noCounty
  }

  return county
}

export const getCountyFromName = (name: string): County => {
  const county = Object.values(counties).find((county) => county.name === name)

  if (!county) {
    return noCounty
  }
  return county
}

export const getCountyFromJurisdiction = (jurisdiction: string | number | undefined): County => {
  if (!jurisdiction) {
    return noCounty
  }

  const county = Object.values(counties).find((county) => county.id.toString() === jurisdiction.toString())

  if (!county) {
    return noCounty
  }
  return county
}
