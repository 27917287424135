import Box from '@mui/material/Box'
import React from 'react'
import { Outlet, useParams } from 'react-router'
import useReconciliationForcedNavigation from '../../hooks/useReconciliationForcedNavigation'
import { FetchError } from '../../models/api'
import Loader from '../Loader'
import LoadingFetchError from '../LoadingFetchError'

export default function ProtectedReconciliationRoute(): JSX.Element {
  const { accountId: accountIdParam } = useParams()
  const accountId = parseInt(accountIdParam ?? '')
  const { isContextLoading, contextHasErrors, contextError, refetchContext } =
    useReconciliationForcedNavigation(accountId)

  if (contextHasErrors) {
    const error = contextError as FetchError
    return (
      <Box mt={2}>
        <LoadingFetchError errorMessage={error.message} tryAgain={refetchContext} />
      </Box>
    )
  }

  if (isContextLoading) {
    return <Loader loading />
  }

  return <Outlet />
}
