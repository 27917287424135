import Alert from '@mui/material/Alert'
import MuiSnackbar from '@mui/material/Snackbar'
import React from 'react'

export interface SnackbarProps {
  message: string | JSX.Element
  open?: boolean
  onClose?: () => void
  severity?: 'success' | 'info' | 'warning' | 'error'
  autoHideDuration?: number
}
export default function Snackbar(props: SnackbarProps): JSX.Element {
  const { message, severity, onClose, open = true, autoHideDuration = 6000 } = props

  return (
    <MuiSnackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MuiSnackbar>
  )
}
