import { NavigateFunction, NavigateOptions } from 'react-router-dom'
import paths from './paths'

export function goToHomePage(navigate: NavigateFunction, options?: NavigateOptions): void {
  navigate(paths.home, options)
}

export function goToBasicCostPage(navigate: NavigateFunction): void {
  navigate(paths.basicCost)
}

export function goToCountySettingsPage(navigate: NavigateFunction): void {
  navigate(paths.countySettings)
}

export function goToPropertyClassificationPage(navigate: NavigateFunction): void {
  navigate(paths.propertyClassification)
}

export function goToAccounts(navigate: NavigateFunction): void {
  navigate(paths.accounts)
}

export function goToLoginPage(navigate: NavigateFunction): void {
  navigate(paths.login, { replace: true })
}

export function goToAppraiserRegionsPage(navigate: NavigateFunction): void {
  navigate(paths.appraiserRegions)
}

export function goToLeaseMasterAccountsPage(navigate: NavigateFunction): void {
  navigate(paths.leaseMasterAccountsPage)
}

interface GoToLeaseMasterOverviewPageParams {
  accountNumber: string // Change this to account id
  taxYear: number
}

export function goToLeaseMasterAccountOverviewPage(
  navigate: NavigateFunction,
  params: GoToLeaseMasterOverviewPageParams,
): void {
  const leaseMasterOverviewURI = paths.leaseMasterOverviewPage.replace(
    ':accountNumber',
    params.accountNumber.toString(),
  )
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${leaseMasterOverviewURI}?${queryParams}`)
}

export function goToLeaseMasterPaymentPostingPage(navigate: NavigateFunction): void {
  navigate(paths.leaseMasterPaymentPostingPage)
}

export function goToLeaseMasterPaymentReconciliationPage(navigate: NavigateFunction): void {
  navigate(paths.leaseMasterPaymentReconciliationPage)
}

export function goToLeaseMasterAssetReconciliationPage(navigate: NavigateFunction): void {
  navigate(paths.leaseMasterAssetReconciliation)
}

export interface GoToOverviewPagesParams {
  accountId: number | string
  taxYear: number
}

function getTaxYearsQueryParams(taxYear: number): string {
  const queryParams = new URLSearchParams(location.search)
  queryParams.set('taxYear', taxYear.toString())

  return queryParams.toString()
}

export function goToAccountOverviewPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountOverviewURI = paths.accountOverview.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountOverviewURI}?${queryParams}`)
}

export function goToAccountAssociatedAccountsPage(
  navigate: NavigateFunction,
  params: GoToOverviewPagesParams,
): void {
  const accountOverviewURI = paths.accountAssociatedAccounts.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountOverviewURI}?${queryParams}`)
}

export function goToAccountPropertyAssetListPage(
  navigate: NavigateFunction,
  params: GoToOverviewPagesParams,
): void {
  const accountPropertyAssetListURI = paths.accountPropertyAssetList.replace(
    ':accountId',
    params.accountId.toString(),
  )
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountPropertyAssetListURI}?${queryParams}`)
}

export function goToAccountPaymentsPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountPaymentsURI = paths.accountPayments.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountPaymentsURI}?${queryParams}`)
}

export function goToAccountDelinquencyPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountDelinquencyURI = paths.accountDelinquency.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountDelinquencyURI}?${queryParams}`)
}

export function goToAccountNotesPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountNotesURI = paths.accountNotes.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountNotesURI}?${queryParams}`)
}

export function goToAccountUsersPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountUsersURI = paths.accountUsers.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountUsersURI}?${queryParams}`)
}

export function goToProfile(navigate: NavigateFunction): void {
  navigate(paths.profile)
}

export function goToAccountTypes(navigate: NavigateFunction): void {
  navigate(paths.accountType)
}

export function goToSicCodeMatersPage(navigate: NavigateFunction): void {
  navigate(paths.sicCodeMasters)
}
export function goToSicCodesPage(navigate: NavigateFunction): void {
  navigate(paths.sicCodes)
}

export function goToSicSiloCodesPage(navigate: NavigateFunction): void {
  navigate(paths.sicSiloCodes)
}

export function goToSubSiloCodesPage(navigate: NavigateFunction): void {
  navigate(paths.subSiloCodes)
}

export function goToUsersPage(navigate: NavigateFunction): void {
  navigate(paths.adminUsers)
}

export function goToRolesPage(navigate: NavigateFunction): void {
  navigate(paths.roles)
}

export function goToPaymentSearchPage(navigate: NavigateFunction): void {
  navigate(paths.paymentSearch)
}

export function goToPaymentSummaryPage(navigate: NavigateFunction): void {
  navigate(paths.paymentSummary)
}

export function goToPaymentReturnedPaymentPage(navigate: NavigateFunction): void {
  navigate(paths.paymentReturnedPayment)
}

export function goToPaymentDelinquentReturnedPaymentPage(navigate: NavigateFunction): void {
  navigate(paths.paymentDelinquentYear)
}

export function goToPaymentTotalsPage(navigate: NavigateFunction): void {
  navigate(paths.paymentTotals)
}

export function goToStatusReportsPage(navigate: NavigateFunction): void {
  navigate(paths.statusReport)
}

export function goToAccountingReportsPage(navigate: NavigateFunction): void {
  navigate(paths.accountingReports)
}

export function goToMissingAppraiserRegionReportPage(navigate: NavigateFunction): void {
  navigate(paths.missingAppraiserRegionReport)
}

export function goToLetterAndFormReportsPage(navigate: NavigateFunction): void {
  navigate(paths.letterAndFormReports)
}

export function goToNotificationFileReportsPage(navigate: NavigateFunction): void {
  navigate(paths.notificationFileReports)
}

export function goToDepositTotalReportsPage(navigate: NavigateFunction): void {
  navigate(paths.depositTotalsReports)
}

export function goToDelinquentSummaryPage(navigate: NavigateFunction): void {
  navigate(paths.delinquentSummary)
}
export function goToAppraisalRegionReportsPage(navigate: NavigateFunction): void {
  navigate(paths.appraisalRegionReport)
}
export interface GoToLeaseOverviewPagesParams {
  accountNumber: string
  taxYear: number
}

export function goToLeaseMasterOverviewPage(
  navigate: NavigateFunction,
  params: GoToLeaseOverviewPagesParams,
): void {
  const leaseMasterOverviewURI = paths.leaseMasterOverviewPage.replace(
    ':accountNumber',
    params.accountNumber.toString(),
  )
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${leaseMasterOverviewURI}?${queryParams}`)
}

export function goToLeaseMasterAreasPage(navigate: NavigateFunction, params: GoToLeaseOverviewPagesParams): void {
  const leaseMasterAreasURI = paths.leaseMasterAreasPage.replace(':accountNumber', params.accountNumber.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${leaseMasterAreasURI}?${queryParams}`)
}

export function goToLeaseMasterNotesPage(navigate: NavigateFunction, params: GoToLeaseOverviewPagesParams): void {
  const leaseMasterNotesURI = paths.leaseMasterNotesPage.replace(':accountNumber', params.accountNumber.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${leaseMasterNotesURI}?${queryParams}`)
}

export function goToLeaseMasterDelinquencyPage(
  navigate: NavigateFunction,
  params: GoToLeaseOverviewPagesParams,
): void {
  const leaseMasterDelinquencyURI = paths.leaseMasterDelinquencyPage.replace(
    ':accountNumber',
    params.accountNumber.toString(),
  )
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${leaseMasterDelinquencyURI}?${queryParams}`)
}

export interface GoToReconcilationPagesParams {
  accountId: number | string
}

export function goToReconciliationPage(navigate: NavigateFunction, params: GoToReconcilationPagesParams): void {
  const reconciliationPageURI = paths.reconciliation.replace(':accountId', params.accountId.toString())
  navigate(reconciliationPageURI)
}

export function goToReconciliationAccountPage(
  navigate: NavigateFunction,
  params: GoToReconcilationPagesParams,
): void {
  const reconciliationPageURI = paths.reconciliationAccountInformation.replace(
    ':accountId',
    params.accountId.toString(),
  )
  navigate(reconciliationPageURI)
}

export function goToReconciliationContactsPage(
  navigate: NavigateFunction,
  params: GoToReconcilationPagesParams,
): void {
  const reconciliationPageURI = paths.reconciliationContacts.replace(':accountId', params.accountId.toString())
  navigate(reconciliationPageURI)
}

export function goToReconciliationPropertyAssetsPage(
  navigate: NavigateFunction,
  params: GoToReconcilationPagesParams,
): void {
  const reconciliationPageURI = paths.reconciliationPropertyAssets.replace(
    ':accountId',
    params.accountId.toString(),
  )
  navigate(reconciliationPageURI)
}

export function goToReconciliationLessorsPage(
  navigate: NavigateFunction,
  params: GoToReconcilationPagesParams,
): void {
  const reconciliationPageURI = paths.reconciliationLessors.replace(':accountId', params.accountId.toString())
  navigate(reconciliationPageURI)
}

export function goToReconciliationLeasedAssetsPage(
  navigate: NavigateFunction,
  params: GoToReconcilationPagesParams,
): void {
  const reconciliationPageURI = paths.reconciliationLeasedAssets.replace(':accountId', params.accountId.toString())
  navigate(reconciliationPageURI)
}

export function goToReconciliationSummaryPage(
  navigate: NavigateFunction,
  params: GoToReconcilationPagesParams,
): void {
  const reconciliationPageURI = paths.reconciliationSummary.replace(':accountId', params.accountId.toString())
  navigate(reconciliationPageURI)
}

export function goToDelinquentAdjustmentPage(navigate: NavigateFunction): void {
  navigate(paths.delinquentAdjustment)
}

export function goToVoidDelinquentPaymentPage(navigate: NavigateFunction): void {
  navigate(paths.voidDelinquentPayment)
}

export function goToMasterHomePage(navigate: NavigateFunction, options?: NavigateOptions): void {
  navigate(paths.masterHome, options)
}

export function goToMasterBasicCostsPage(navigate: NavigateFunction): void {
  navigate(paths.masterBasicCost)
}

export function goToMasterAccountTypesPage(navigate: NavigateFunction): void {
  navigate(paths.masterAccountType)
}

export function goToMasterPropertyClassificationPage(navigate: NavigateFunction): void {
  navigate(paths.masterPropertyClassification)
}
