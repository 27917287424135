import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import paths from '../../utils/paths'

export default function ProtectedMasterRoute(): JSX.Element {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to={paths.masterLogin} replace />
  }

  return <Outlet />
}
