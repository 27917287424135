import { ReconciliationStep } from '../models/reconciliation'
import paths from './paths'
import { parseUrlParameters } from '.'

export function getStepFromPath(pathname: string): ReconciliationStep | null {
  if (pathname.includes('/account')) {
    return ReconciliationStep.AccountInformation
  }

  if (pathname.includes('/contacts')) {
    return ReconciliationStep.Contacts
  }

  if (pathname.includes('/assets')) {
    return ReconciliationStep.PropertyAssetList
  }

  if (pathname.includes('/leased-assets')) {
    return ReconciliationStep.LeasedAssetList
  }

  if (pathname.includes('/summary')) {
    return ReconciliationStep.Summary
  }

  return null
}

export function mapToStep(stepName: string): ReconciliationStep {
  if (stepName === 'Account Information') {
    return ReconciliationStep.AccountInformation
  }

  if (stepName === 'Contacts') {
    return ReconciliationStep.Contacts
  }

  if (stepName === 'Contacts') {
    return ReconciliationStep.Contacts
  }

  if (stepName === 'Property Asset List') {
    return ReconciliationStep.PropertyAssetList
  }

  if (stepName === 'Leased Asset List') {
    return ReconciliationStep.LeasedAssetList
  }

  if (stepName === 'Summary & Signature') {
    return ReconciliationStep.Summary
  }

  if (stepName === 'Reconciled') {
    return ReconciliationStep.Reconciled
  }

  throw new Error(`Could not map step "${stepName}"`)
}

export function nextStep(completedSteps: ReconciliationStep[], accountId: number | string): string {
  if (!completedSteps.includes(ReconciliationStep.AccountInformation)) {
    return parseUrlParameters(paths.reconciliationAccountInformation, { accountId })
  }

  if (!completedSteps.includes(ReconciliationStep.Contacts)) {
    return parseUrlParameters(paths.reconciliationContacts, { accountId })
  }

  if (!completedSteps.includes(ReconciliationStep.PropertyAssetList)) {
    return parseUrlParameters(paths.reconciliationPropertyAssets, { accountId })
  }

  if (!completedSteps.includes(ReconciliationStep.LeasedAssetList)) {
    return parseUrlParameters(paths.reconciliationLeasedAssets, { accountId })
  }

  if (!completedSteps.includes(ReconciliationStep.Summary)) {
    return parseUrlParameters(paths.reconciliationSummary, { accountId })
  }

  return parseUrlParameters(paths.reconciliationAccountInformation, { accountId })
}

export function hasWorkflowBeenCompleted(completedSteps: ReconciliationStep[]): boolean {
  const hasBeenCompleted = [
    ReconciliationStep.AccountInformation,
    ReconciliationStep.Contacts,
    ReconciliationStep.PropertyAssetList,
    ReconciliationStep.LeasedAssetList,
    ReconciliationStep.Summary,
    ReconciliationStep.Reconciled,
  ].every((step) => completedSteps.includes(step))

  return hasBeenCompleted
}
