import jwtDecode, { JwtPayload } from 'jwt-decode'
import React from 'react'
import { AuthInformation, User } from '../models/api'
import { getCountyFromJurisdiction } from '../utils/counties'
import useLocalStorage from './useLocalStorage'

interface JwtCustomPayload extends JwtPayload {
  userId: number
  fullName: string
  taxYear: number
  jurisdictionId: number
  isMasterUser?: boolean
}

interface AuthProviderProps {
  children: React.ReactNode
}

interface AuthContext {
  user: User
  authInformation: AuthInformation
  setUser?: React.Dispatch<React.SetStateAction<boolean>>
  storeToken?: (token: string) => void
  signOut?: () => void
}

const AuthContext = React.createContext<AuthContext>({} as AuthContext)

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [user, setUser] = useLocalStorage<User | null>('user', null)
  const [authInformation, setAuthInformation] = useLocalStorage<AuthInformation | null>('authInformation', null)

  const storeToken = (token: string): void => {
    const payload = jwtDecode<JwtCustomPayload>(token)
    const newAuthInformation: AuthInformation = {
      token: token,
      userId: payload.userId,
      jurisdictionId: payload.jurisdictionId,
      isMasterUser: payload.isMasterUser ?? false,
    }
    const county = getCountyFromJurisdiction(payload.jurisdictionId)

    setAuthInformation(newAuthInformation)
    setUser({
      accountNumber: payload.userId.toString(),
      businessName: 'Utah County',
      county,
      fullName: payload.fullName.toString(),
    })
  }

  const signOut = (): void => {
    setUser(null)
    setAuthInformation(null)
  }

  const authContext = {
    user: user as User,
    authInformation: authInformation as AuthInformation,
    storeToken,
    signOut,
  }

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
}

export default function useAuth(): AuthContext {
  return React.useContext(AuthContext)
}
