import { AuthInformation } from '../models/api'

const keys = {
  authInformation: 'authInformation',
  user: 'user',
} as const

export function getAuthInformation(): AuthInformation | null {
  const authInformation = localStorage.getItem(keys.authInformation)

  if (!authInformation) {
    return null
  }

  return JSON.parse(authInformation)
}

export function setAuthInformation(authInformation: AuthInformation): void {
  localStorage.setItem(keys.authInformation, JSON.stringify(authInformation))
}

export const logout = (): void => {
  localStorage.removeItem(keys.authInformation)
  localStorage.removeItem(keys.user)

  if (window.location.pathname !== '/login') {
    window.location.href = '/login'
  }
}
