import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useState } from 'react'

export interface MenuItem {
  text: string
  icon?: JSX.Element
  selected?: boolean
  hidden?: boolean
  onClick?: () => void
  items?: MenuItem[]
}

interface ItemProps {
  menuItem: MenuItem
}

function MenuItem(props: ItemProps): JSX.Element {
  const { menuItem } = props
  const isParent = menuItem.items && menuItem.items.length > 0

  const [open, setOpen] = useState(() => {
    return isParent && menuItem.selected
  })

  const handleClick = (): void => {
    setOpen(!open)
  }

  if (menuItem.hidden) {
    return <></>
  }

  return (
    <>
      <ListItem selected={!isParent && menuItem.selected} onClick={isParent ? handleClick : menuItem.onClick}>
        <ListItemButton>
          {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
          <ListItemText primary={menuItem.text} sx={{ fontSize: '0.875rem' }} disableTypography />
          {isParent && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </ListItem>

      {isParent && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MenuItemDrawer menuItems={menuItem.items || []} childList />
        </Collapse>
      )}
    </>
  )
}

interface ListProps {
  menuItems: MenuItem[]
  childList?: boolean
}

export default function MenuItemDrawer(props: ListProps): JSX.Element {
  return (
    <List sx={{ pl: props.childList ? 2 : 0 }}>
      {props.menuItems.map((item) => (
        <MenuItem key={item.text} menuItem={item} />
      ))}
    </List>
  )
}
