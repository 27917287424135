import { blue, lightBlue, grey } from '@mui/material/colors/'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles'
// TODO: Replace this import when Material-UI v5 comes out
// https://github.com/mui-org/material-ui/issues/13394

export default createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: lightBlue[400],
      contrastText: '#fff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: { height: '100vh', background: grey[100] },
        '#root': { height: '100%' },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: grey[900],
          '&:hover': {
            backgroundColor: blue[800],
          },
        },
      },
    },
  },
})
