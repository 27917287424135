import React from 'react'
import { Routes, Route } from 'react-router-dom'

// Components
import Layout from '../Layout'
import ProtectedMasterRoute from '../ProtectedMasterRoute'
import ProtectedRoute from '../ProtectedRoute'
import SuspenseRoute from './SuspenseRoute'
import { protectedMasterRoutes, protectedRoutes, publicRoutes, RouteType } from './routeConfig'

// Pages
const PageNotFound = React.lazy(() => import('../../pages/PageNotFound'))

function renderRoutes(routes: RouteType[]): JSX.Element[] {
  return routes.map(({ path, component: Component, children }, index) => (
    <Route
      key={`${path}-${index}`}
      path={path}
      element={<SuspenseRoute Component={Component as React.ComponentType} />}
    >
      {children && renderRoutes(children)}
    </Route>
  ))
}

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<Layout />}>
        {renderRoutes(publicRoutes)}

        <Route element={<ProtectedRoute />}>{renderRoutes(protectedRoutes)}</Route>

        <Route element={<ProtectedMasterRoute />}>{renderRoutes(protectedMasterRoutes)}</Route>
      </Route>

      <Route element={<Layout isPageNotFound />}>
        <Route path="*" element={<SuspenseRoute Component={PageNotFound} />} />
      </Route>
    </Routes>
  )
}
