import { endpointUrl } from '../config'
import useAuth from '../hooks/useAuth'
import useGlobalContext from '../hooks/useGlobalContext'
import { logout } from '../utils/localStorage'

type HeaderType = RequestInit['headers'] & {
  'Content-Type': string
  authorization?: string
  'Current-Tax-Year'?: string
}

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers'],
): ((variables?: TVariables) => Promise<TData>) => {
  const { authInformation } = useAuth()
  const { currentTaxYear } = useGlobalContext()

  const headers: HeaderType = {
    'Content-Type': 'application/json',
    ...options,
  }

  if (authInformation?.token) {
    headers['authorization'] = `bearer ${authInformation.token}`
  }

  if (currentTaxYear) {
    headers['Current-Tax-Year'] = String(currentTaxYear)
  }

  return async (variables?: TVariables) => {
    const res = await fetch(endpointUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const json = await res.json()

    if (
      json.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED' ||
      json.errors?.[0]?.extensions?.exception?.status === 401
    ) {
      logout()
    }

    if (json.errors) {
      const { message } = json.errors[0] || {}
      throw new Error(message || 'Error…')
    }

    return json.data
  }
}
