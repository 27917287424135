import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import ProfileIcon from '@mui/icons-material/Person'
import { LinearProgress } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useReconciliationContext from '../../hooks/useReconciliationContext'
import { goToLoginPage, goToProfile } from '../../utils/routeActions'
import NavigationDrawer from './NavigationDrawer'

interface Props {
  hideNavigationDrawer?: boolean
}

export default function Header(props: Props): JSX.Element {
  const classes = useStyles()
  const { authInformation, user, signOut } = useAuth()
  const [pageIsLoading, setPageIsLoading] = React.useState(false)
  const { removeContext } = useReconciliationContext()
  const location = useLocation()
  const navigate = useNavigate()

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { t } = useTranslation()

  React.useEffect(() => {
    const handleRouteChangeStart = (): void => setPageIsLoading(true)
    const handleRouteChangeComplete = (): void => setPageIsLoading(false)

    // Simulate route change start
    handleRouteChangeStart()

    // Simulate an asynchronous operation
    const timer = setTimeout(() => {
      handleRouteChangeComplete()
    }, 500)

    return () => clearTimeout(timer)
  }, [location])

  const isReconciliationPath = location.pathname.includes('/reconciliation')

  const handleDrawerToggle = (): void => {
    setMobileOpen((prevState) => !prevState)
  }

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleProfileClick = (): void => {
    setAnchorEl(null)
    goToProfile(navigate)
  }

  const handleSignOut = (): void => {
    handleClose()
    signOut?.()
    removeContext?.()
    goToLoginPage(navigate)
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" classes={{ root: classes.appBar }}>
        <Toolbar>
          {user && (
            <IconButton
              color="inherit"
              aria-label="open menu"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: isReconciliationPath ? 'inherit' : 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Stack
            justifyContent="space-between"
            direction={{ sx: 'column', sm: 'row', md: 'row' }}
            flex="1"
            rowGap={1}
          >
            <Stack direction="column" spacing={0} alignItems="baseline">
              {user && (
                <Typography variant="body2" component="span" display="block">
                  {authInformation.isMasterUser ? 'Master User' : `#${user?.county?.id}`}
                </Typography>
              )}
              <Typography variant="h6" component="h1" lineHeight={1}>
                {user?.county?.name}
              </Typography>
            </Stack>
          </Stack>
          {user && (
            <Stack>
              <Box>
                <IconButton
                  id="basic-button"
                  aria-haspopup="true"
                  aria-label={t('userMenu')}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-expanded={open}
                  onClick={handleClick}
                >
                  <AccountCircleIcon sx={{ color: 'white' }} />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {!authInformation?.isMasterUser && (
                    <MenuItem onClick={handleProfileClick}>
                      <ListItemIcon>
                        <ProfileIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('profile')} />
                    </MenuItem>
                  )}

                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('signOut')} />
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          )}
        </Toolbar>
        {pageIsLoading && <LinearProgress />}
      </AppBar>
      {!props.hideNavigationDrawer && user && (
        <NavigationDrawer open={mobileOpen} onClose={handleDrawerToggle} alwaysTemporary={isReconciliationPath} />
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))
